const vi = {
	jiantizhongwen: "Tiếng Trung giản thể",
	xianlu: "đường kẻ",
	dianwei: "Giá trị tổng hiện tại",
	wodezichan: "tài sản của tôi",
	touzikuaixun: "về chúng tôi",
	jiaoyiyonghu: "người dùng",
	jiaoyipinglei: "Loại cược",
	danlunhuoli: "lợi nhuận vòng đơn",
	jiaoyiriqi: "Ngày đặt cược",
	dengluhouchakan: "Đăng nhập để xem",
	shouye: "trang đầu",
	jiaoyidating: "Phòng cá cược",
	yucun: "đặt trước",
	zoushi: "xu hướng",
	wode: "của tôi",
	baocun: 'cứu',
	genggai: 'thay đổi hình đại diện',
	zuiditouzi: "đầu tư tối thiểu",
	zuidijinru: "nhập cảnh tối thiểu",
	jichulicai: "Quản lý tài chính cơ bản",
	zishenlicai: "Thành viên bạc",
	dashilicai: "thành viên vàng",
	zhuanjialicai: "Thành viên bạch kim",
	基础理财: "Quản lý tài chính cơ bản",
	资深理财: "Quản lý tài chính cao cấp",
	大师理财: "Thạc sĩ tài chính",
	专家理财: "Chuyên gia tài chính",
	huiyuanquanyi: "lợi ích thành viên",
	zuoriyingli: "lợi nhuận của ngày hôm qua",
	daikuanzonge: 'lợi nhuận của ngày hôm qua',
	zongzichan: "Tổng tài sản",
	chucunjin: "Tiết kiệm tiền",
	jinriyingli: "lợi nhuận hôm nay",
	zhuanchu: "chuyển ra ngoài",
	cunru: "tiền gửi",
	zhanghuxinxni: "thông tin tài khoản",
	lishizhangdan: "hóa đơn lịch sử",
	zijinminxi: "Chi tiết tài trợ",
	renwuzhongxin: "trung tâm nhiệm vụ",
	xiaoxigonggao: "thông báo tin tức",
	tuijianfenxiang: "Đề nghị chia sẻ",
	daikuanxiangqing:'Chi tiết khoản vay',
	huodongzhongxin: "Trung tâm hoạt động",
	jibenziliao: "thông tin cơ bản",
	dengluzhanghao: "đăng nhập tài khoản",
	nicheng: "tên nick",
	idcard: 'mã số',
	congxiangce: 'chọn từ album',
    xitongtu: 'Hình ảnh mặc định của hệ thống',
	shezhi: "cài đặt",
	genggaixianlu: "thay đổi lộ trình",
	guanyuwomen: "về chúng tôi",
	xitongshezhi: "Cài đặt hệ thống",
	yuyanshezhi: "cài đặt ngôn ngữ",
	tuichudenglu: "đăng xuất",
	bangdingyinhangka: "Liên kết thẻ ngân hàng",
	bangdingxuniqianbao: "Liên kết ví ảo",
	anquanma: "mã bảo vệ",
	xiugailenglumima: "Thay đổi mật khẩu đăng nhập",
	yinhangkaguanli: "quản lý thẻ ngân hàng",
	tianjiayinhangka: "thêm thẻ ngân hàng",
	xuniqianbaoguanli: "Quản lý ví ảo",
	tianjiaxuniqianbao: "Thêm ví ảo",
	chiakren: "chủ thẻ",
	yinhangkakahao: "số thẻ ngân hàng",
	yinhangmingcheng: "tên ngân hàng",
	yhkTps: "Vui lòng liên kết thẻ ngân hàng của chính chủ thẻ",
	nixiqianbaodizhi: "địa chỉ ví ảo",
	xuniqianbaoleixing: "Loại ví ảo",
	xnqbTps: "Nhắc nhở: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni",
	plchikaren: "Vui lòng nhập chủ thẻ",
	plkahao: "Vui lòng nhập số thẻ của chủ thẻ",
	plyinhangmingcheng: "Vui lòng nhập tên ngân hàng",
	plqbdz: "Vui lòng nhập địa chỉ ví ảo",
	plqblx: "Vui lòng nhập loại ví ảo",
	shezhianquanma: "đặt mã bảo vệ",
	yuananquanma: "mã bảo mật ban đầu",
	xinanquanma: "mã bảo vệ mới",
	planquanma: "Vui lòng nhập mã bảo mật ban đầu",
	plxinanquanma: "Vui lòng nhập mã bảo mật mới",
	queren: "xác nhận",
	quxiao: "Hủy bỏ",
	anquanmaTps: "Vui lòng đặt mã bảo mật, vui lòng không giống với mật khẩu thẻ ngân hàng",
	anquanmaTpsTow: "Kính gửi khách hàng, mã bảo mật này là mật khẩu của bạn khi chuyển ra ngoài, vì lý do bảo mật, vui lòng cố gắng không giống với mật khẩu đăng nhập",
	xiugaidenglu: "Thay đổi mật khẩu đăng nhập",
	jiumima: "Mật khẩu cũ",
	xinmima: "mật khẩu mới",
	querenmima: "Xác nhận mật khẩu",
	pljiumima: "Vui lòng nhập mật khẩu cũ",
	plxinmima: "Vui lòng nhập mật khẩu mới",
	plquerenmima: "vui lòng nhập lại mật khẩu",
	wancheng: "Hoàn thành",
	kaishitouzi: "bắt đầu đầu tư",
	kaiTps: "Khi dòng hiện tại không khả dụng, hãy thử chuyển sang dòng khác",
	zuiyou: "tốt nhất",
	dangaqianxianlu: "dòng hiện tại",
	dangqianbanben: "Phiên bản hiện tại",
	banquansuoyou: "Đã đăng ký Bản quyền",
	yinglizhendong: "Sốc thu nhập",
	gensuixitong: "làm theo hệ thống",
	zhuanchujine: "Số tiền chuyển khoản",
	plzhuanchu: "Vui lòng nhập số tiền chuyển",
	zhuanrujine: "Số tiền chuyển khoản",
	plzhuanru: "Vui lòng nhập số tiền chuyển",
	zhuanchuTps: "Nhắc nhở: Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng của nền tảng.",
	xuanzeyh: "Vui lòng chọn thẻ ngân hàng rút tiền",
	xuanzehb: "Vui lòng chọn ví ảo để rút tiền",
	tijiaoshenqing: "nộp đơn",
	shuruanquanma: "Vui lòng nhập mã bảo vệ",
	xianshangcunru: "gửi tiền trực tuyến",
	saomacunru: "USDT",
	wangyincunru: "tiền gửi ngân hàng",
	shijian: "thời gian",
	upshijian: "thời gian bắt đầu",
	downshijian: "Thời gian kết thúc",
	wuTps: "Không có dữ liệu liên quan~",
	jiazaicx: "tải lại",
	di: "KHÔNG.",
	lun: "bánh xe",
	weiyingli: "Sự mất mát",
	yishouli: "Đã được chấp nhận",
	zhuangtai: "tình trạng",
	chongzhi: "cài lại",
	riqi: "ngày",
	lianxikefu: 'liên hệ với dịch vụ khách hàng',
	CapActive: {
		chongzhi: "tiền gửi",
		tixian: "Rút tiền",
		goumai: "Mua",
		yingli: "lợi nhuận",
		daikuan:'khoản vay',
        huankuan:'trả nợ',
		kuisun: 'Sự mất mát',
		zengsong: "quà ",
		weitongguo: "không vượt qua",
		yitongguo: "đi qua",
		yijujue: "vật bị loại bỏ",
		all: 'tất cả'
	},
	zhanneixiaoxi: "tin tức nhà ga",
	pingtaigonggao: "thông báo nền tảng",
	fuzhilanjie: "sao chép đường dẫn",
	denglu: "Đăng nhập",
	zhuce: "đăng ký",
	jizhumima: "nhớ mật khẩu",
	wangjimima: "quên mật khẩu",
	youkedenglu: "khách truy cập đăng nhập",
	zhucezhanghao: "đăng ký tài khoản",
	plusername: "Vui lòng nhập tên người dùng",
	plpassword: "xin vui lòng nhập mật khẩu",
	replpassword:'Vui lòng nhập lại mật khẩu',
	plinvitecode: "Vui lòng nhập mã thư mời",
	plidcard: 'Vui lòng nhập số CMND',
	tuijianyaoqingma: 'Mã lời mời được đề xuất',
	wanchengzhuce: "đăng ký hoàn tất",
	yiyouzhanghao: "tài khoản hiện có",
	qingdenglu: "làm ơn đăng nhập",
	remenhuati: "chủ đề nóng",
	news1: "Giới thiệu nền tảng",
	news2: "Cách chơi",
	news3: "Hoạt động",
	renliulan: "mọi người duyệt",
	hezhi: "và giá trị",
	lunshu: "Số vòng",
	做多: "Lớn",
	做空: "Nhỏ",
	多单: "Đơn hàng lớn",
	多双: "Đôi Lớn",
	空单: "Danh sách nhỏ",
	空双: "Đôi nhỏ",
	平单: "Độc thân",
	平双: "Đôi",
	极阴: "Cực nhỏ",
	极阳: "Lớn",
	qi: "Trông chờ",
	juli: "khoảng cách",
	lunjiezhi: "làm tròn lên",
	yifengpan: "đóng cửa",
	yiguanbi: "đóng cửa",
	yonghu: "người dùng",
	danlunjiaoyi: "Số tiền đặt cược",
	caozuo: "vận hành",
	pljoin: "Tham gia phòng chat thành công",
	pltingshou: "Sản phẩm đã ngừng sản xuất",
	gengou: "Theo dõi mua hàng",
	quedinggengou: "Bạn có chắc chắn muốn theo dõi giao dịch mua không?",
	wanjia: "người chơi",
	leibie: "loại",
	jine: "số tiền",
	gouru: "mua",
	zanweigouru: "chưa mua",
	qigou: "mua",
	gourushuliang: "Số lượng mua",
	changgui: "thông thường",
	shuzi: "con số",
	yinglihezhi: "lợi nhuận và giá trị",
	shouyijieshao: "Giới thiệu lợi ích",
	lijicunru: "gửi tiền ngay bây giờ",
	kaihaojieguo: "Mở kết quả số",
	kaihaoqushi: "xu hướng mở số",
	chanpingshuoming: "Mô tả Sản phẩm",
	qingchuhuancun: "xóa bộ nhớ cache",
	youke: "khách du lịch",
	gongxi: "Chúc mừng",
	zai: "hiện hữu",
	yingli: "lợi nhuận",
	xiadanchenggong: "đặt hàng thành công",
	jiazaizhong: "Đang tải...",
	guanbitg: "Đóng cửa, ngừng mua",
	xiangoudl: "Bạn chỉ có thể mua vòng hiện tại",
	liaotiantip: "Hệ thống đã cấm phát ngôn, hãy chọn mua",
	tishi: "gợi ý",
	zhidao: "biết",
	zhuanchusb: "Không thể chuyển ra ngoài",
	zhuanchusbs: "Số dư không đủ, chuyển không thành công",
	buyxq: "chi tiết mua hàng",
	orderNo: "số hóa đơn",
	plan_name: "Thông tin phòng",
	buyxx: "mua thông tin",
	haoxx: "Chi tiết chọn số",
	buysj: "thời gian mua hàng",
	yilou: "bỏ sót",
	fzjqb: "Sao chép vào clipboard",
	chanpin1: "Thời gian mở thưởng: cứ 3,5 phút có một vòng quay, mở vào 10h00 cùng ngày và kết thúc vào 02h00 ngày hôm sau.",
	chanpin2: "Có 3 số từ 0-9 trong mỗi vòng và tổng của 3 số là kết quả cuối cùng. Bạn có thể đi dài, ngắn, phẳng, đơn, phẳng, lưỡng cực, dương, âm và các số do kết quả của trò chơi. sự đầu tư.",
	Tips: {
		wjmm: "Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để sửa đổi mật khẩu!",
		dlcg: "đăng nhập thành công",
		tjcg: "gửi thành công",
		zanwu: "Không có dữ liệu",
		buyizhi: "Hai mật khẩu không khớp",
		zcbz: "Tài sản của bạn không đủ, hãy gửi tiền",
		pltxje: "Vui lòng nhập số tiền rút",
		zdtx: "Rút tiền tối thiểu 100",
		youke: "Kính gửi khách truy cập, vui lòng hoạt động sau khi đăng ký làm thành viên chính thức.",
		szcg: "thiết lập thành công"
	},
	Recharge: {
		title: "tiền gửi",
		usdtrecharge: "tiền gửi USDT",
		usdtwithdrawal: "Rút USDT",
		bankrecharge: "Tiết kiệm ngân hàng",
		bankwithdrawal: "rút tiền ngân hàng"
	},
	Rechargecoin: {
		title: "tiền gửi USDT",
		downbtn: "bấm chuột để tải xuống",
		downurl: "địa chỉ gửi tiền",
		copybtn: "nhấp để sao chép",
		amount: "hạn ngạch",
		amountplaceholder: "Vui lòng nhập số tiền",
		selectcontract: "chọn hợp đồng",
		walletaddress: "địa chỉ ví",
		walletaddressplaceholder: "Vui lòng nhập địa chỉ ví của bạn",
		confirmbtn: "nhấp để xác nhận",
		sharecode: "Chia sẻ mã QR.png",
		writeamount: "Vui lòng điền số tiền",
		writeaddress: "Vui lòng điền địa chỉ"
	},
	Withdrawcoin: {
		title: "Rút tiền mặt USDT",
		currency: "Tiền tệ",
		selectcontract: "chọn hợp đồng",
		amount: "hạn ngạch",
		yue: "THĂNG BẰNG",
		yueplaceholder: "Vui lòng nhập số tiền",
		walletaddress: "địa chỉ ví",
		walletaddressplaceholder: "Vui lòng nhập địa chỉ ví của bạn",
		confirmbtn: "nhấp để xác nhận",
		sharecode: "Chia sẻ mã QR.png",
		writeamount: "Vui lòng điền số tiền",
		writeaddress: "Vui lòng điền địa chỉ",
		writesuper: "Rút tiền vượt quá số dư"
	},
	Bankrecharge: {
		title: "Tiết kiệm ngân hàng",
		amount: "số tiền",
		yue: "THĂNG BẰNG",
		selectcurrency: "chọn tiền tệ",
		transamount: "số tiền quy đổi",
		confirmbtn: "nhấp để xác nhận",
		yueplaceholder: "Vui lòng nhập số tiền",
		writeamount: "Vui lòng điền số tiền",
		writecurrency: "Vui lòng chọn đơn vị tiền tệ"
	},
	Bankwithdraw: {
		title: "Rút tiền thẻ ngân hàng",
		amount: "số tiền",
		yue: "THĂNG BẰNG",
		selectcurrency: "chọn tiền tệ",
		transamount: "số tiền quy đổi",
		confirmbtn: "nhấp để xác nhận",
		yueplaceholder: "Vui lòng nhập số tiền",
		writeamount: "Vui lòng điền số tiền",
		writecurrency: "Vui lòng chọn đơn vị tiền tệ",
		writesuper: "Rút tiền vượt quá số dư",
		bankinfo: "thông tin ngân hàng",
		acc: "tài khoản",
		accname: "tên tài khoản",
		accbank: "Tài khoản ngân hàng"
	},
    Loandetail:{
        title1: 'Bạn muốn nhận khoản vay ngay lập tức từ chúng tôi?',
        title2: 'Thực hiện theo các bước đơn giản dưới đây!',
        troop1: '1. Ứng dụng email',
        troop2: '2. Ghé thăm văn phòng của chúng tôi',
        content1: 'Đã đến lúc ghé thăm văn phòng của chúng tôi và tạo một hợp đồng cho vay tùy chỉnh cho chính bạn',
        troop3: '3. Ký hợp đồng vay',
        content2: 'Biết các điều khoản và điều kiện, sau đó ký hợp đồng cho vay',
        troop4: '4. Nhận tiền mặt',
        content3: 'Nhận khoản vay tiền mặt của bạn trong vòng một giờ!',
        content4: 'Loại khoản vay Khoản vay cá nhân, cung cấp thông tin cá nhân và tài chính cần thiết như thu nhập, lịch sử tín dụng, vv Xem xét và Đánh giá Đánh giá rủi ro tín dụng của bạn và quyết định có chấp thuận khoản vay hay không. Phê duyệt và Điều kiện Sau khi đơn xin vay của bạn được phê duyệt, bạn sẽ nhận được thông báo phê duyệt khoản vay chính thức với các điều kiện như số tiền vay, lãi suất, thời hạn trả nợ, vv Ký kết hợp đồng.Khoản vay là khoản vay một lần. Trả nợ* là khoản hoàn trả một lần theo hợp đồng. Lãi suất hàng năm bắt đầu từ 1%. Để tránh sự khác biệt về văn hóa, tất cả các hình thức đăng ký đều qua email!',
        confirmtext: 'Đăng ký vay ngay',
    },
	loan: {
		gm9: 'cho vay tài chính',
		jd:'khoản vay',
		jdjl:'Lịch sử cho vay và tín dụng',
		jdzq:'chu kỳ cho vay',
		jdsl:'Số tiền cho vay',
		tj:'nộp',
		zq:'xe đạp',
		time:'thời gian',
		wdzc:'Tất cả sẽ được trả lại',
		jded:'Số tiền vay',
		sshk:'trả nợ',
		hkjl:'Lịch sử trả nợ',
		hksl:'Số tiền hoàn trả',
		nodata:'Không có dữ liệu',
		ksshijian: 'Thời gian bắt đầu',
        jieshujian: 'Thời gian kết thúc',
		t:'bầu trời',
		zt:'tình trạng',
		shz:'đang được xem xét',
		wtg:'Không vượt qua',
		tg:'vượt qua'
	}
}
export default vi;