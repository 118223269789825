const ti = {
	jiantizhongwen: "ภาษาจีนตัวย่อ",
	xianlu: "เส้น",
	dianwei: "จุดปัจจุบัน",
	wodezichan: "ทรัพย์สินของฉัน",
	touzikuaixun: "ข่าวการลงทุน",
	jiaoyiyonghu: "ผู้ใช้ธุรกรรม",
	jiaoyipinglei: "หมวดหมู่ธุรกรรม",
	danlunhuoli: "กำไรรอบเดียว",
	jiaoyiriqi: "วันที่ทำรายการ",
	dengluhouchakan: "เข้าสู่ระบบเพื่อดู",
	shouye: "หน้าแรก",
	jiaoyidating: "ชั้นซื้อขาย",
	yucun: "พรีสโตร์",
	zoushi: "แนวโน้ม",
	wode: "ของฉัน",
	baocun: 'บันทึก',
	genggai: 'เปลี่ยนอวตาร',
	zuiditouzi: "การลงทุนขั้นต่ำ",
	zuidijinru: "รายการขั้นต่ำ",
	jichulicai: "สมาชิกสามัญ",
	zishenlicai: "สมาชิกระดับแพลทินัม",
	dashilicai: "สมาชิกไดมอนด์",
	zhuanjialicai: "สมาชิกวีไอพี",
	基础理财: "การจัดการทางการเงินขั้นพื้นฐาน",
	资深理财: "ผู้บริหารการเงินอาวุโส",
	大师理财: "ปริญญาโทการเงิน",
	专家理财: "ผู้เชี่ยวชาญด้านการเงิน",
	huiyuanquanyi: "สิทธิประโยชน์สำหรับสมาชิก",
	zuoriyingli: "กำไรเมื่อวาน",
	daikuanzonge: 'เงินกู้ทั้งหมด',
	zongzichan: "สินทรัพย์รวม",
	chucunjin: "ประหยัดเงิน",
	jinriyingli: "กำไรวันนี้",
	zhuanchu: "โอนออก",
	cunru: "เงินฝาก",
	zhanghuxinxni: "ข้อมูลเกี่ยวกับบัญชี",
	lishizhangdan: "บิลประวัติศาสตร์",
	zijinminxi: "รายละเอียดทุน",
	renwuzhongxin: "ศูนย์งาน",
	xiaoxigonggao: "ประกาศข่าว",
	tuijianfenxiang: "แนะนำให้แชร์",
	daikuanxiangqing:'รายละเอียดสินเชื่อ',
	huodongzhongxin: "ศูนย์กิจกรรม",
	jibenziliao: "ข้อมูลพื้นฐาน",
	dengluzhanghao: "บัญชีเข้าสู่ระบบ",
	nicheng: "ชื่อเล่น",
	idcard: 'หมายเลขประจำตัวประชาชน',
	congxiangce: 'เลือกจากอัลบั้ม',
    xitongtu: 'ภาพเริ่มต้นของระบบ',
	shezhi: "ติดตั้ง",
	genggaixianlu: "เปลี่ยนเส้นทาง",
	guanyuwomen: "เกี่ยวกับเรา",
	xitongshezhi: "การตั้งค่าระบบ",
	yuyanshezhi: "ตั้งค่าภาษา",
	tuichudenglu: "ออกจากระบบ",
	bangdingyinhangka: "ผูกบัตรธนาคาร",
	bangdingxuniqianbao: "ผูกกระเป๋าเงินเสมือน",
	anquanma: "รหัสรักษาความปลอดภัย",
	xiugailenglumima: "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
	yinhangkaguanli: "การจัดการบัตรธนาคาร",
	tianjiayinhangka: "เพิ่มบัตรธนาคาร",
	xuniqianbaoguanli: "การจัดการกระเป๋าเงินเสมือน",
	tianjiaxuniqianbao: "เพิ่มกระเป๋าเงินเสมือน",
	chiakren: "ผู้ถือบัตร",
	yinhangkakahao: "หมายเลขบัตรธนาคาร",
	yinhangmingcheng: "ชื่อธนาคาร",
	yhkTps: "กรุณาผูกบัตรธนาคารของผู้ถือบัตรเอง",
	nixiqianbaodizhi: "ที่อยู่กระเป๋าเงินเสมือน",
	xuniqianbaoleixing: "ประเภทกระเป๋าเงินเสมือน",
	xnqbTps: "คำเตือน: โปรดกรอกประเภทที่อยู่ USDT เช่น: trc20, erc20, omni",
	plchikaren: "กรุณากรอกชื่อผู้ถือบัตร",
	plkahao: "กรุณากรอกหมายเลขบัตรผู้ถือบัตร",
	plyinhangmingcheng: "กรุณากรอกชื่อธนาคาร",
	plqbdz: "โปรดป้อนที่อยู่กระเป๋าเงินเสมือน",
	plqblx: "โปรดป้อนประเภทกระเป๋าเงินเสมือน",
	shezhianquanma: "ตั้งรหัสความปลอดภัย",
	yuananquanma: "รหัสความปลอดภัยเดิม",
	xinanquanma: "รหัสความปลอดภัยใหม่",
	planquanma: "กรุณาใส่รหัสความปลอดภัยเดิม",
	plxinanquanma: "กรุณาใส่รหัสความปลอดภัยใหม่",
	queren: "ยืนยัน",
	quxiao: "ยกเลิก",
	anquanmaTps: "กรุณาตั้งรหัสความปลอดภัย อย่าให้เหมือนกับรหัสผ่านของบัตรธนาคาร",
	anquanmaTpsTow: "เรียน คุณลูกค้า รหัสรักษาความปลอดภัยนี้เป็นรหัสผ่านของคุณเมื่อทำการโอนย้ายออก เพื่อความปลอดภัย โปรดอย่าใช้รหัสเดียวกันกับรหัสผ่านสำหรับเข้าสู่ระบบ",
	xiugaidenglu: "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
	jiumima: "รหัสผ่านเก่า",
	xinmima: "รหัสผ่านใหม่",
	querenmima: "ยืนยันรหัสผ่าน",
	pljiumima: "กรุณาใส่รหัสผ่านเก่า",
	plxinmima: "กรุณาใส่รหัสผ่านใหม่",
	plquerenmima: "กรุณาใส่รหัสผ่านอีกครั้ง",
	wancheng: "เสร็จ",
	kaishitouzi: "เริ่มลงทุน",
	kaiTps: "เมื่อสายปัจจุบันใช้งานไม่ได้ ให้ลองเปลี่ยนไปใช้สายอื่น",
	zuiyou: "ดีที่สุด",
	dangaqianxianlu: "บรรทัดปัจจุบัน",
	dangqianbanben: "รุ่นปัจจุบัน",
	banquansuoyou: "สงวนลิขสิทธิ์",
	yinglizhendong: "รายรับช็อก",
	gensuixitong: "ทำตามระบบ",
	zhuanchujine: "ยอดโอน",
	plzhuanchu: "กรุณากรอกจำนวนเงินโอน",
	zhuanrujine: "ยอดโอน",
	plzhuanru: "กรุณากรอกจำนวนเงินโอน",
	zhuanchuTps: "คำเตือน: หากคุณมีคำถามใด ๆ โปรดติดต่อฝ่ายบริการลูกค้าของแพลตฟอร์ม",
	xuanzeyh: "กรุณาเลือกบัตรธนาคารถอนเงิน",
	xuanzehb: "โปรดเลือกกระเป๋าเงินเสมือนสำหรับการถอน",
	tijiaoshenqing: "ส่งใบสมัคร",
	shuruanquanma: "กรุณาใส่รหัสความปลอดภัย",
	xianshangcunru: "ฝากเงินออนไลน์",
	saomacunru: "USDT",
	wangyincunru: "เงินฝากธนาคาร",
	shijian: "เวลา",
	upshijian: "เวลาเริ่มต้น",
	downshijian: "เวลาสิ้นสุด",
	wuTps: "ไม่มีข้อมูลที่เกี่ยวข้อง~",
	jiazaicx: "โหลดใหม่",
	di: "เลขที่",
	lun: "ล้อ",
	weiyingli: "ไม่ได้ประโยชน์",
	yishouli: "ได้รับการยอมรับ",
	zhuangtai: "สถานะ",
	chongzhi: "รีเซ็ต",
	riqi: "วันที่",
	lianxikefu: 'ติดต่อฝ่ายบริการลูกค้า',
	CapActive: {
		chongzhi: "เงินฝาก",
		tixian: "เบิกเงิน",
		goumai: "ซื้อ",
		yingli: "กำไร",
		daikuan:'เงินกู้',
        huankuan:'การชำระคืน',
		kuisun: 'การสูญเสีย',
		zengsong: "ของขวัญ",
		weitongguo: "ไม่ผ่าน",
		yitongguo: "ผ่าน",
		yijujue: "ปฏิเสธ",
		all: 'ทั้งหมด'
	},
	zhanneixiaoxi: "ข่าวสถานี",
	pingtaigonggao: "ประกาศแพลตฟอร์ม",
	fuzhilanjie: "คัดลอกลิงค์",
	denglu: "เข้าสู่ระบบ",
	zhuce: "ลงทะเบียน",
	jizhumima: "จดจำรหัสผ่าน",
	wangjimima: "ลืมรหัสผ่าน",
	youkedenglu: "เข้าสู่ระบบผู้เยี่ยมชม",
	zhucezhanghao: "ลงทะเบียนบัญชี",
	plusername: "กรุณาใส่ชื่อผู้ใช้",
	plpassword: "กรุณาใส่รหัสผ่าน",
	replpassword:'กรุณากรอกรหัสผ่านอีกครั้ง',
	plinvitecode: "กรุณากรอกรหัสเชิญ",
	plidcard: 'กรุณากรอกหมายเลขประจำตัวประชาชน',
	tuijianyaoqingma: 'รหัสเชิญที่แนะนำ',
	wanchengzhuce: "การลงทะเบียนเสร็จสมบูรณ์",
	yiyouzhanghao: "บัญชีที่มีอยู่",
	qingdenglu: "กรุณาเข้าสู่ระบบ",
	remenhuati: "ประเด็นร้อน",
	news1: "การแนะนำแพลตฟอร์ม",
	news2: "วิธีการเล่น",
	news3: "กิจกรรม",
	renliulan: "ผู้คนเรียกดู",
	hezhi: "และความคุ้มค่า",
	lunshu: "จำนวนรอบ",
	做多: "ทำมากขึ้น",
	做空: "สั้น",
	多单: "สั่งยาว",
	多双: "หลายคู่",
	空单: "คำสั่งซื้อที่ว่างเปล่า",
	空双: "คู่ที่ว่างเปล่า",
	平单: "สั่งแบน",
	平双: "แบนคู่",
	极阴: "มืดครึ้มมาก",
	极阳: "ดวงอาทิตย์สุดขีด",
	qi: "คาดหวัง",
	juli: "ระยะทาง",
	lunjiezhi: "ปัดขึ้น",
	yifengpan: "ปิด",
	yiguanbi: "ปิด",
	yonghu: "ผู้ใช้",
	danlunjiaoyi: "การทำธุรกรรมรอบเดียว",
	caozuo: "ดำเนินงาน",
	pljoin: "เข้าร่วมห้องสนทนาสำเร็จ",
	pltingshou: "สินค้าเลิกผลิตแล้ว",
	gengou: "ติดตามการซื้อ",
	quedinggengou: "คุณแน่ใจหรือไม่ว่าต้องการติดตามการซื้อ",
	wanjia: "ผู้เล่น",
	leibie: "หมวดหมู่",
	jine: "จำนวนเงิน",
	gouru: "ซื้อ",
	zanweigouru: "ยังไม่ได้ซื้อ",
	qigou: "ซื้อ",
	gourushuliang: "ปริมาณการซื้อ",
	changgui: "ธรรมดา",
	shuzi: "ตัวเลข",
	yinglihezhi: "กำไรและมูลค่า",
	shouyijieshao: "แนะนำประโยชน์",
	lijicunru: "ฝากเงินเลย",
	kaihaojieguo: "ผลเลขที่เปิด",
	kaihaoqushi: "เทรนด์เลขเปิด",
	chanpingshuoming: "รายละเอียดสินค้า",
	qingchuhuancun: "ล้างแคช",
	youke: "นักท่องเที่ยว",
	gongxi: "ยินดีด้วย",
	zai: "มีอยู่",
	yingli: "กำไร",
	xiadanchenggong: "สั่งสำเร็จ",
	jiazaizhong: "กำลังโหลด...",
	guanbitg: "ปิดครับ งดซื้อครับ",
	xiangoudl: "คุณสามารถซื้อรอบปัจจุบันเท่านั้น",
	liaotiantip: "ระบบได้ทำการแบนคำพูด กรุณาเลือกซื้อ",
	tishi: "คำใบ้",
	zhidao: "รู้",
	zhuanchusb: "โอนออกไม่สำเร็จ",
	zhuanchusbs: "ยอดเงินไม่เพียงพอ โอนไม่สำเร็จ",
	buyxq: "รายละเอียดการซื้อ",
	orderNo: "หมายเลขบิล",
	plan_name: "ข้อมูลห้องพัก",
	buyxx: "ข้อมูลการซื้อ",
	haoxx: "รายละเอียดการเลือกเบอร์",
	buysj: "เวลาซื้อ",
	yilou: "ละเว้น",
	fzjqb: "คัดลอกไปที่คลิปบอร์ดแล้ว",
	chanpin1: "เวลาเปิด: ทุก 3.5 นาทีเป็นรอบ เปิดเวลา 10.00 น. ในวันเดียวกัน และปิดเวลา 02.00 น. ของวันถัดไป",
	chanpin2: "ในแต่ละรอบจะมีตัวเลข 3 ตัวจาก 0-9 และผลรวมของตัวเลข 3 ตัวคือผลลัพธ์สุดท้าย คุณสามารถไป long, short, flat, single, flat, bipolar, positive, Negative และตัวเลขที่เป็นผลมาจาก การลงทุน.",
	Tips: {
		wjmm: "หากคุณลืมรหัสผ่าน โปรดติดต่อฝ่ายบริการลูกค้าเพื่อแก้ไข!",
		dlcg: "เข้าสู่ระบบสำเร็จ",
		tjcg: "ส่งเรียบร้อยแล้ว",
		zanwu: "ไม่มีข้อมูล",
		buyizhi: "รหัสผ่านทั้งสองไม่ตรงกัน",
		zcbz: "ทรัพย์สินของคุณไม่เพียงพอ กรุณาฝาก",
		pltxje: "กรุณากรอกจำนวนเงินที่ถอน",
		zdtx: "ถอนขั้นต่ำ 100",
		youke: "เรียน ผู้เยี่ยมชม โปรดดำเนินการหลังจากลงทะเบียนเป็นสมาชิกอย่างเป็นทางการ",
		szcg: "ตั้งค่าสำเร็จ"
	},
	Recharge: {
		title: "ฝาก & ถอน",
		usdtrecharge: "เติมเงิน USDT",
		usdtwithdrawal: "การถอนเงิน USDT",
		bankrecharge: "เติมเงินธนาคาร",
		bankwithdrawal: "ถอนเงินผ่านธนาคาร"
	},
	Rechargecoin: {
		title: "เติมเงิน USDT",
		downbtn: "คลิกเพื่อดาวน์โหลด",
		downurl: "ที่อยู่ฝาก",
		copybtn: "คลิกเพื่อคัดลอก",
		amount: "โควต้า",
		amountplaceholder: "กรุณากรอกจำนวนเงิน",
		selectcontract: "เลือกสัญญา",
		walletaddress: "ที่อยู่กระเป๋าเงิน",
		walletaddressplaceholder: "กรุณากรอกที่อยู่กระเป๋าสตางค์ของคุณ",
		confirmbtn: "คลิกเพื่อยืนยัน",
		sharecode: "แบ่งปัน QR code.png",
		writeamount: "กรุณากรอกจำนวนเงิน",
		writeaddress: "กรุณากรอกที่อยู่"
	},
	Withdrawcoin: {
		title: "การถอนเงินสด USDT",
		currency: "สกุลเงิน",
		selectcontract: "เลือกสัญญา",
		amount: "โควต้า",
		yue: "สมดุล",
		yueplaceholder: "กรุณากรอกจำนวนเงิน",
		walletaddress: "ที่อยู่กระเป๋าเงิน",
		walletaddressplaceholder: "กรุณากรอกที่อยู่กระเป๋าสตางค์ของคุณ",
		confirmbtn: "คลิกเพื่อยืนยัน",
		sharecode: "แบ่งปัน QR code.png",
		writeamount: "กรุณากรอกจำนวนเงิน",
		writeaddress: "กรุณากรอกที่อยู่",
		writesuper: "การถอนเกินยอดคงเหลือ"
	},
	Bankrecharge: {
		title: "เติมเงินธนาคาร",
		amount: "จำนวนเงิน",
		yue: "สมดุล",
		selectcurrency: "เลือกสกุลเงิน",
		transamount: "จำนวนเงินที่แปลง",
		confirmbtn: "คลิกเพื่อยืนยัน",
		yueplaceholder: "กรุณากรอกจำนวนเงิน",
		writeamount: "กรุณากรอกจำนวนเงิน",
		writecurrency: "กรุณาเลือกสกุลเงิน"
	},
	Bankwithdraw: {
		title: "ถอนเงินสดบัตรธนาคาร",
		amount: "จำนวนเงิน",
		yue: "สมดุล",
		selectcurrency: "เลือกสกุลเงิน",
		transamount: "จำนวนเงินที่แปลง",
		confirmbtn: "คลิกเพื่อยืนยัน",
		yueplaceholder: "กรุณากรอกจำนวนเงิน",
		writeamount: "กรุณากรอกจำนวนเงิน",
		writecurrency: "กรุณาเลือกสกุลเงิน",
		writesuper: "การถอนเกินยอดคงเหลือ",
		bankinfo: "ข้อมูลธนาคาร",
		acc: "บัญชี",
		accname: "ชื่อบัญชี",
		accbank: "บัญชีธนาคาร"
	},
    Loandetail:{
        title1: 'ต้องการรับเงินกู้ทันทีจากเราหรือไม่?',
        title2: 'ทำตามขั้นตอนง่ายๆ ด้านล่างนี้!',
        troop1: '1. สมัครอีเมล์',
        troop2: '2. เยี่ยมชมสำนักงานของเรา',
        content1: 'ได้เวลาเยี่ยมชมสำนักงานของเราและสร้างสัญญาเงินกู้แบบกำหนดเองสำหรับตัวคุณเอง',
        troop3: '3. ลงนามในสัญญาเงินกู้',
        content2: 'ทราบข้อกำหนดและเงื่อนไขแล้วลงนามในสัญญาเงินกู้',
        troop4: '4. รับเงินสด',
        content3: 'รับสินเชื่อเงินสดของคุณภายในหนึ่งชั่วโมง!',
        content4: 'ประเภทสินเชื่อ สินเชื่อบุคคล ให้ข้อมูลส่วนตัวและการเงินที่จำเป็น เช่น รายได้ ประวัติสินเชื่อ เป็นต้น ทบทวนและประเมินผล ประเมินความเสี่ยงด้านเครดิตของคุณและตัดสินใจว่าจะอนุมัติเงินกู้หรือไม่ การอนุมัติและเงื่อนไข เมื่อคำขอสินเชื่อของคุณได้รับการอนุมัติ คุณจะได้รับการแจ้งเตือนการอนุมัติสินเชื่ออย่างเป็นทางการพร้อมเงื่อนไขต่างๆ เช่น จำนวนเงินกู้ อัตราดอกเบี้ย ระยะเวลาชำระคืน เป็นต้น การเซ็นสัญญา.เงินกู้เป็นการกู้ยืมครั้งเดียว การชำระคืน* เป็นการชำระคืนครั้งเดียวตามสัญญา อัตราดอกเบี้ยรายปีเริ่มต้นที่ 1% เพื่อหลีกเลี่ยงความแตกต่างทางวัฒนธรรม สมัครทุกรูปแบบทางอีเมล!',
        confirmtext: 'สมัครสินเชื่อได้เลย',
    },
	loan: {
		gm9: 'การให้กู้ยืมทางการเงิน',
		jd:'เงินกู้',
		jdjl:'ประวัติสินเชื่อและเครดิต',
		jdzq:'วงจรการให้กู้ยืม',
		jdsl:'ปริมาณสินเชื่อ',
		tj:'ส่ง',
		zq:'วงจร',
		time:'เวลา',
		wdzc:'จะต้องคืนทั้งหมด.',
		jded:'จำนวนเงินกู้',
		sshk:'การชำระคืน',
		hkjl:'ประวัติการชำระหนี้',
		hksl:'จำนวนการชำระคืน',
		nodata:'ไม่มีข้อมูล',
		ksshijian: 'เวลาเริ่มต้น',
        jieshujian: 'เวลาสิ้นสุด',
		t:'ท้องฟ้า',
		zt:'สถานะ',
		shz:'ภายใต้การทบทวน',
		wtg:'ไม่ผ่าน',
		tg:'ผ่าน'
	}
}
export default ti;