import request from "@/utils/request";
export default {
  // 积分日志
 //   充值页面
  loan() {
    return request.post("/api/loan/index", {});
  },
  getTotalLoan(){
    return request.post("/api/loanOrder/total", {});
  },
  getLoanHistory(){
    return request.post("/api/loanOrder/index", {});
  },
  getRebackHistory(){
    return request.post("/api/rebackOrder/index", {});
  },
  addLoan(data){
    return request.post("/api/loanOrder/add",data);
  },
  addReback(data){
    return request.post("/api/rebackOrder/add",data);
  }
};
