const kr = {
	jiantizhongwen: "중국어 간체",
	xianlu: "선",
	dianwei: "현재 포인트",
	wodezichan: "내 자산",
	touzikuaixun: "투자뉴스",
	jiaoyiyonghu: "트랜잭션 사용자",
	jiaoyipinglei: "거래 카테고리",
	danlunhuoli: "단일 라운드 이익",
	jiaoyiriqi: "거래 날짜",
	dengluhouchakan: "보기 위해 로그인",
	shouye: "첫 장",
	jiaoyidating: "거래소",
	yucun: "프리스토어",
	zoushi: "경향",
	wode: "내 거",
	baocun: '구하다',
	genggai: '아바타 변경',
	zuiditouzi: "최소 투자",
	zuidijinru: "최소 입장",
	jichulicai: "일반회원",
	zishenlicai: "플래티넘 회원",
	dashilicai: "다이아몬드 회원",
	zhuanjialicai: "VIP 회원",
	基础理财: "기본재무관리",
	资深理财: "고위 재무 관리",
	大师理财: "마스터 파이낸스",
	专家理财: "전문가 금융",
	huiyuanquanyi: "회원혜택",
	zuoriyingli: "어제의 이익",
	daikuanzonge: '총 대출',
	zongzichan: "총 자산",
	chucunjin: "돈을 절약",
	jinriyingli: "오늘 이익",
	zhuanchu: "전출하다",
	cunru: "보증금",
	zhanghuxinxni: "계정 정보",
	lishizhangdan: "역사적 법안",
	zijinminxi: "펀딩 내역",
	renwuzhongxin: "태스크 센터",
	xiaoxigonggao: "뉴스 발표",
	tuijianfenxiang: "공유 추천",
	daikuanxiangqing:'대출내역',
	huodongzhongxin: "액티비티 센터",
	jibenziliao: "기본 정보",
	dengluzhanghao: "로그인 계정",
	nicheng: "닉네임",
	idcard: '식별 번호',
	congxiangce: '시스템 기본 그림',
    xitongtu: '시스템 기본 그림',
	shezhi: "설정",
	genggaixianlu: "경로 변경",
	guanyuwomen: "회사 소개",
	xitongshezhi: "환경 설정",
	yuyanshezhi: "언어 설정",
	tuichudenglu: "로그아웃",
	bangdingyinhangka: "은행 카드 바인딩",
	bangdingxuniqianbao: "가상 지갑 바인딩",
	anquanma: "보안 코드",
	xiugailenglumima: "로그인 비밀번호 변경",
	yinhangkaguanli: "은행 카드 관리",
	tianjiayinhangka: "은행 카드 추가",
	xuniqianbaoguanli: "가상 지갑 관리",
	tianjiaxuniqianbao: "가상 지갑 추가",
	chiakren: "카드홀더",
	yinhangkakahao: "은행 카드 번호",
	yinhangmingcheng: "은행 이름",
	yhkTps: "카드 소지자의 은행 카드를 바인딩하세요.",
	nixiqianbaodizhi: "가상 지갑 주소",
	xuniqianbaoleixing: "가상 지갑 유형",
	xnqbTps: "알림: trc20, erc20, omni와 같은 USDT 주소 유형을 입력하세요.",
	plchikaren: "카드홀더를 입력해주세요",
	plkahao: "카드 소지자의 카드 번호를 입력하십시오",
	plyinhangmingcheng: "은행 이름을 입력하세요.",
	plqbdz: "가상 지갑 주소를 입력해주세요.",
	plqblx: "가상 지갑 유형을 입력하세요.",
	shezhianquanma: "보안 코드 설정",
	yuananquanma: "원래 보안 코드",
	xinanquanma: "새 보안 코드",
	planquanma: "원래 보안 코드를 입력하십시오",
	plxinanquanma: "새 보안 코드를 입력하세요.",
	queren: "확인하다",
	quxiao: "취소",
	anquanmaTps: "보안 코드를 설정하십시오. 은행 카드 비밀번호와 동일하지 마십시오.",
	anquanmaTpsTow: "고객님께 이 보안코드는 이체시 비밀번호입니다. 보안상의 이유로 로그인 비밀번호와 동일하지 않도록 해 주세요.",
	xiugaidenglu: "로그인 비밀번호 변경",
	jiumima: "기존 비밀번호",
	xinmima: "새 비밀번호",
	querenmima: "비밀번호 확인",
	pljiumima: "이전 비밀번호를 입력하세요.",
	plxinmima: "새 비밀번호를 입력하세요",
	plquerenmima: "비밀번호를 다시 입력해주세요",
	wancheng: "마치다",
	kaishitouzi: "투자를 시작하다",
	kaiTps: "현재 회선을 사용할 수 없으면 다른 회선으로 전환하십시오.",
	zuiyou: "최상의",
	dangaqianxianlu: "현재 라인",
	dangqianbanben: "현재 버전",
	banquansuoyou: "판권 소유",
	yinglizhendong: "어닝 쇼크",
	gensuixitong: "시스템을 따르다",
	zhuanchujine: "이체 금액",
	plzhuanchu: "이체금액을 입력해주세요",
	zhuanrujine: "이체 금액",
	plzhuanru: "이체금액을 입력해주세요",
	zhuanchuTps: "알림: 질문이 있는 경우 플랫폼 고객 서비스에 문의하십시오.",
	xuanzeyh: "인출 은행 카드를 선택하십시오",
	xuanzehb: "출금할 가상지갑을 선택해주세요.",
	tijiaoshenqing: "지원서 제출",
	shuruanquanma: "보안 코드를 입력하세요",
	xianshangcunru: "온라인 입금",
	saomacunru: "USDT",
	wangyincunru: "은행 예금",
	shijian: "시간",
	upshijian: "시작 시간",
	downshijian: "종료 시간",
	wuTps: "관련자료가 없습니다~",
	jiazaicx: "새로고침",
	di: "아니요.",
	lun: "바퀴",
	weiyingli: "무익한",
	yishouli: "수락됨",
	zhuangtai: "상태",
	chongzhi: "초기화",
	riqi: "날짜",
	lianxikefu: '고객 서비스에 문의',
	CapActive: {
		chongzhi: "보증금",
		tixian: "돈을 인출",
		goumai: "구입하다",
		yingli: "이익",
		daikuan:'대출',
        huankuan:'반환',
		kuisun: '손실',
		zengsong: "선물",
		weitongguo: "통과하지 못함",
		yitongguo: "합격",
		yijujue: "거부",
		all: '모두'
	},
	zhanneixiaoxi: "방송국 뉴스",
	pingtaigonggao: "플랫폼 발표",
	fuzhilanjie: "링크 복사",
	denglu: "로그인",
	zhuce: "등록하다",
	jizhumima: "암호 기억",
	wangjimima: "비밀번호를 잊어버리다",
	youkedenglu: "방문자 로그인",
	zhucezhanghao: "계정 등록",
	plusername: "사용자 이름을 입력하십시오",
	plpassword: "비밀번호를 입력 해주세요",
	replpassword:'비밀번호를 다시 입력해주세요',
	plinvitecode: "초대코드를 입력해주세요",
	plidcard: '주민등록번호를 입력해주세요',
	tuijianyaoqingma: '추천 초대코드',
	wanchengzhuce: "등록 완료",
	yiyouzhanghao: "기존 계정",
	qingdenglu: "로그인 해주세요",
	remenhuati: "화제",
	news1: "플랫폼 소개",
	news2: "게임 방법",
	news3: "활동",
	renliulan: "사람들이 탐색",
	hezhi: "그리고 가치",
	lunshu: "라운드 수",
	做多: "더 많은 일을 할",
	做空: "짧은",
	多单: "긴 주문",
	多双: "여러 쌍",
	空单: "빈 주문",
	空双: "빈 더블",
	平单: "플랫 주문",
	平双: "플랫 더블",
	极阴: "매우 흐림",
	极阳: "극단적인 태양",
	qi: "예상하다",
	juli: "거리",
	lunjiezhi: "모으다",
	yifengpan: "닫은",
	yiguanbi: "닫은",
	yonghu: "사용자",
	danlunjiaoyi: "일회성 거래",
	caozuo: "작동하다",
	pljoin: "대화방에 성공적으로 참여",
	pltingshou: "제품이 단종되었습니다",
	gengou: "후속 구매",
	quedinggengou: "구매를 계속하시겠습니까?",
	wanjia: "플레이어",
	leibie: "범주",
	jine: "금액",
	gouru: "구입하다",
	zanweigouru: "아직 구매하지 않음",
	qigou: "구입",
	gourushuliang: "구매 수량",
	changgui: "전통적인",
	shuzi: "숫자",
	yinglihezhi: "이익과 가치",
	shouyijieshao: "혜택 소개",
	lijicunru: "지금 입금하세요",
	kaihaojieguo: "숫자 결과 열기",
	kaihaoqushi: "오픈 넘버 트렌드",
	chanpingshuoming: "제품 설명",
	qingchuhuancun: "캐시 지우기",
	youke: "관광객",
	gongxi: "축하해요",
	zai: "존재하다",
	yingli: "이익",
	xiadanchenggong: "성공적으로 주문",
	jiazaizhong: "로드 중...",
	guanbitg: "휴무, 구매 중지",
	xiangoudl: "현재 라운드만 구매할 수 있습니다.",
	liaotiantip: "시스템에서 발언을 금지했습니다. 구매를 선택하세요.",
	tishi: "힌트",
	zhidao: "알고 있었다",
	zhuanchusb: "이전하지 못했습니다.",
	zhuanchusbs: "잔액 부족, 이체 실패",
	buyxq: "구매내역",
	orderNo: "청구서 번호",
	plan_name: "객실 안내",
	buyxx: "구매정보",
	haoxx: "번호 선택 세부정보",
	buysj: "구매 시간",
	yilou: "생략하다",
	fzjqb: "클립보드에 복사됨",
	chanpin1: "개장 시간: 3.5분마다 라운드로 당일 오전 10시에 개장하고 익일 오전 02시에 폐장합니다.",
	chanpin2: "매 라운드마다 0~9까지 3개의 숫자가 나오며 3개의 숫자의 합이 최종 결과가 되며 투자 결과에 따라 롱, 숏, 플 랫, 싱글, 플랫, 바이폴라, 애노드, 숫자로 갈 수 있습니다.",
	Tips: {
		wjmm: "비밀번호를 잊은 경우 고객 서비스에 문의하여 수정하십시오!",
		dlcg: "성공적 로그인",
		tjcg: "성공적으로 제출되었습니다",
		zanwu: "데이터 없음",
		buyizhi: "두 비밀번호가 일치하지 않습니다",
		zcbz: "자산이 부족합니다. 입금해 주세요.",
		pltxje: "출금금액을 입력해주세요",
		zdtx: "최소 인출 100",
		youke: "방문자 여러분, 정회원 등록 후 이용해주시기 바랍니다.",
		szcg: "성공적으로 설정"
	},
	Recharge: {
		title: "입금 및 출금",
		usdtrecharge: "USDT 충전",
		usdtwithdrawal: "USDT 출금",
		bankrecharge: "은행 충전",
		bankwithdrawal: "은행 인출"
	},
	Rechargecoin: {
		title: "USDT 충전",
		downbtn: "다운로드하려면 클릭",
		downurl: "입금 주소",
		copybtn: "클릭하여 복사",
		amount: "몫",
		amountplaceholder: "금액을 입력하세요.",
		selectcontract: "계약을 선택",
		walletaddress: "지갑 주소",
		walletaddressplaceholder: "지갑주소를 입력해주세요",
		confirmbtn: "클릭하여 확인",
		sharecode: "QR 코드 공유.png",
		writeamount: "금액을 기입해주세요",
		writeaddress: "주소를 입력해주세요"
	},
	Withdrawcoin: {
		title: "USDT 현금 인출",
		currency: "통화",
		selectcontract: "계약을 선택",
		amount: "몫",
		yue: "균형",
		yueplaceholder: "금액을 입력하세요.",
		walletaddress: "지갑 주소",
		walletaddressplaceholder: "지갑주소를 입력해주세요",
		confirmbtn: "클릭하여 확인",
		sharecode: "QR 코드 공유.png",
		writeamount: "금액을 기입해주세요",
		writeaddress: "주소를 입력해주세요",
		writesuper: "출금 잔액 초과"
	},
	Bankrecharge: {
		title: "은행 충전",
		amount: "금액",
		yue: "균형",
		selectcurrency: "통화 선택",
		transamount: "환산금액",
		confirmbtn: "클릭하여 확인",
		yueplaceholder: "금액을 입력하세요.",
		writeamount: "금액을 기입해주세요",
		writecurrency: "통화를 선택하세요."
	},
	Bankwithdraw: {
		title: "은행 카드 현금 인출",
		amount: "금액",
		yue: "균형",
		selectcurrency: "통화 선택",
		transamount: "환산금액",
		confirmbtn: "클릭하여 확인",
		yueplaceholder: "금액을 입력하세요.",
		writeamount: "금액을 기입해주세요",
		writecurrency: "통화를 선택하세요.",
		writesuper: "출금 잔액 초과",
		bankinfo: "은행 정보",
		acc: "계정",
		accname: "계정 이름",
		accbank: "계좌 은행"
	},
    Loandetail:{
        title1: '즉시 대출을 원하십니까?',
        title2: '아래의 간단한 단계를 따르십시오!',
        troop1: '1. 이메일 접수',
        troop2: '2. 사무실 방문',
        content1: '저희 사무실을 방문하여 직접 맞춤형 대출 계약서를 작성할 시간입니다.',
        troop3: '3. 대출 계약서 서명',
        content2: '약관을 숙지한 후 대출 계약서에 서명하십시오.',
        troop4: '4. 현금 수령',
        content3: '한 시간 안에 현금 대출을 받으십시오!',
        content4: '대출 유형 개인 대출, 소득, 신용 기록 등 필요한 개인 및 금융 정보를 제공합니다. 검토 및 평가 신용 위험을 평가하고 대출 승인 여부를 결정합니다. 승인 및 조건 대출 신청이 승인되면 대출 금액, 이자율, 상환 기간 등과 같은 조건이 포함된 정식 대출 승인 통지를 받게 됩니다. 계약 체결.대출은 일회성 대출이며, 상환*은 계약에 따른 일회성 상환입니다. 이율은 연 1%부터이며, 문화차이를 피하기 위해 모든 신청은 이메일로 받습니다!',
        confirmtext: '지금 대출 신청하기',
    },
	loan: {
		gm9: '금융 대출',
		jd:'대출',
		jdjl:'대출 및 신용 이력',
		jdzq:'대출주기',
		jdsl:'대출 수량',
		tj:'제출하다',
		zq:'주기',
		time:'시간',
		wdzc:'모두 반환 예정',
		jded:'대출 금액',
		sshk:'반환',
		hkjl:'상환내역',
		hksl:'상환금액',
		nodata:'데이터 없음',
		ksshijian: '시작 시간',
        jieshujian: '종료 시간',
		t:'하늘',
		zt:'상태',
		shz:'검토중',
		wtg:'통과하지 못함',
		tg:'통과하다'
	}
}
export default kr;