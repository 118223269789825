const ge = {
	jiantizhongwen: "Vereinfachtes Chinesisch",
	xianlu: "Linie",
	dianwei: "aktueller Punkt",
	wodezichan: "mein Vermögen",
	touzikuaixun: "Investitionsnachrichten",
	jiaoyiyonghu: "Transaktionsbenutzer",
	jiaoyipinglei: "Transaktionskategorie",
	danlunhuoli: "Einzelrundengewinn",
	jiaoyiriqi: "Transaktionsdatum",
	dengluhouchakan: "Zum Ansehen anmelden",
	shouye: "Titelseite",
	jiaoyidating: "Handelssaal",
	yucun: "Vorspeichern",
	zoushi: "Trend",
	wode: "meins",
	baocun: 'speichern',
	genggai: 'Avatar ändern',
	zuiditouzi: "Mindestinvestition",
	zuidijinru: "Mindesteintritt",
	jichulicai: "Ordentliches Mitglied",
	zishenlicai: "Platin Mitglied",
	dashilicai: "Diamond Mitgliedschaft",
	zhuanjialicai: "VIP Mitglied",
	基础理财: "Grundlegendes Finanzmanagement",
	资深理财: "Leitendes Finanzmanagement",
	大师理财: "Master-Finanzen",
	专家理财: "Experte für Finanzen",
	huiyuanquanyi: "Vorteile für Mitglieder",
	zuoriyingli: "der Gewinn von gestern",
	daikuanzonge: 'Gesamtdarlehen',
	zongzichan: "Gesamtvermögen",
	chucunjin: "Geld sparen",
	jinriyingli: "Gewinn heute",
	zhuanchu: "raus transferieren",
	cunru: "Kaution",
	zhanghuxinxni: "Kontoinformationen",
	lishizhangdan: "historische Rechnung",
	zijinminxi: "Finanzierungsdetails",
	renwuzhongxin: "Aufgabencenter",
	xiaoxigonggao: "Nachrichtenankündigung",
	tuijianfenxiang: "Empfohlen zum Teilen",
	daikuanxiangqing:'Kreditdetails',
	huodongzhongxin: "Aktivitätszentrum",
	jibenziliao: "Grundinformation",
	dengluzhanghao: "Login Account",
	nicheng: "Spitzname",
	idcard: 'Identifikationsnummer',
	congxiangce: 'Aus Album auswählen',
    xitongtu: 'Standardbild des Systems',
	shezhi: "aufstellen",
	genggaixianlu: "Route ändern",
	guanyuwomen: "über uns",
	xitongshezhi: "Systemeinstellungen",
	yuyanshezhi: "Spracheinstellungen",
	tuichudenglu: "Abmelden",
	bangdingyinhangka: "Bankkarte binden",
	bangdingxuniqianbao: "Virtuelle Geldbörse binden",
	anquanma: "Sicherheitscode",
	xiugailenglumima: "Login-Passwort ändern",
	yinhangkaguanli: "Verwaltung von Bankkarten",
	tianjiayinhangka: "Bankkarte hinzufügen",
	xuniqianbaoguanli: "Virtuelle Geldbörsenverwaltung",
	tianjiaxuniqianbao: "Fügen Sie eine virtuelle Geldbörse hinzu",
	chiakren: "Kartenhalter",
	yinhangkakahao: "Bankkartennummer",
	yinhangmingcheng: "Bank Name",
	yhkTps: "Bitte binden Sie die eigene Bankkarte des Karteninhabers ein",
	nixiqianbaodizhi: "virtuelle Wallet-Adresse",
	xuniqianbaoleixing: "Virtueller Wallet-Typ",
	xnqbTps: "Erinnerung: Bitte geben Sie den USDT-Adresstyp ein, z. B. trc20, erc20, omni",
	plchikaren: "Bitte geben Sie den Karteninhaber ein",
	plkahao: "Bitte geben Sie die Kartennummer des Karteninhabers ein",
	plyinhangmingcheng: "Bitte geben Sie den Banknamen ein",
	plqbdz: "Bitte geben Sie die virtuelle Wallet-Adresse ein",
	plqblx: "Bitte geben Sie den Typ der virtuellen Geldbörse ein",
	shezhianquanma: "Sicherheitscode festlegen",
	yuananquanma: "Original-Sicherheitscode",
	xinanquanma: "neuer Sicherheitscode",
	planquanma: "Bitte geben Sie den Original-Sicherheitscode ein",
	plxinanquanma: "Bitte geben Sie einen neuen Sicherheitscode ein",
	queren: "bestätigen",
	quxiao: "Stornieren",
	anquanmaTps: "Bitte legen Sie einen Sicherheitscode fest, der nicht mit dem Passwort der Bankkarte identisch sein darf",
	anquanmaTpsTow: "Sehr geehrter Kunde, dieser Sicherheitscode ist Ihr Passwort bei der Überweisung. Aus Sicherheitsgründen versuchen Sie bitte, nicht mit dem Login-Passwort übereinzustimmen",
	xiugaidenglu: "Login-Passwort ändern",
	jiumima: "Altes Passwort",
	xinmima: "Neues Kennwort",
	querenmima: "Bestätige das Passwort",
	pljiumima: "Bitte geben Sie das alte Passwort ein",
	plxinmima: "Bitte geben Sie ein neues Passwort ein",
	plquerenmima: "Bitte geben Sie das Passwort erneut ein",
	wancheng: "Beenden",
	kaishitouzi: "Fangen Sie an zu investieren",
	kaiTps: "Wenn die aktuelle Leitung nicht verfügbar ist, versuchen Sie, zu einer anderen Leitung zu wechseln",
	zuiyou: "am besten",
	dangaqianxianlu: "aktuelle Zeile",
	dangqianbanben: "aktuelle Version",
	banquansuoyou: "Alle Rechte vorbehalten",
	yinglizhendong: "Gewinnschock",
	gensuixitong: "dem System folgen",
	zhuanchujine: "Überweisungsbetrag",
	plzhuanchu: "Bitte geben Sie den Überweisungsbetrag ein",
	zhuanrujine: "Überweisungsbetrag",
	plzhuanru: "Bitte geben Sie den Überweisungsbetrag ein",
	zhuanchuTps: "Erinnerung: Bei Fragen wenden Sie sich bitte an den Plattform-Kundendienst.",
	xuanzeyh: "Bitte wählen Sie eine Bankkarte für die Auszahlung aus",
	xuanzehb: "Bitte wählen Sie eine virtuelle Geldbörse für die Auszahlung aus",
	tijiaoshenqing: "Bewerbung einreichen",
	shuruanquanma: "Bitte geben Sie den Sicherheitscode ein",
	xianshangcunru: "Online einzahlen",
	saomacunru: "USDT",
	wangyincunru: "Bankdepot",
	shijian: "Zeit",
	upshijian: "Anfangszeit",
	downshijian: "Endzeit",
	wuTps: "Keine relevanten Daten~",
	jiazaicx: "Neu laden",
	di: "NEIN.",
	lun: "Rad",
	weiyingli: "unrentabel",
	yishouli: "Akzeptiert",
	zhuangtai: "Zustand",
	chongzhi: "zurücksetzen",
	riqi: "Datum",
	lianxikefu: 'Kontaktieren Sie den Kundendienst',
	CapActive: {
		chongzhi: "Kaution",
		tixian: "Geld abheben",
		goumai: "Kaufen",
		yingli: "profitieren",
		daikuan:'Darlehen',
        huankuan:'Rückzahlung',
		kuisun: 'Verlust',
		zengsong: "Geschenk",
		weitongguo: "Nicht bestanden",
		yitongguo: "bestanden",
		yijujue: "abgelehnt",
		all: "alle"
	},
	zhanneixiaoxi: "Sendernachrichten",
	pingtaigonggao: "Ankündigung der Plattform",
	fuzhilanjie: "Link kopieren",
	denglu: "Anmeldung",
	zhuce: "registrieren",
	jizhumima: "Passwort merken",
	wangjimima: "vergessen Sie das Passwort",
	youkedenglu: "Besucher-Login",
	zhucezhanghao: "Account registrieren",
	plusername: "Bitte geben sie einen Benutzernamen ein",
	plpassword: "Bitte Passwort eingeben",
	replpassword:'Bitte geben Sie das Passwort erneut ein',
	plinvitecode: "Bitte geben Sie den Einladungscode ein",
	plidcard: 'Bitte geben Sie die ID-Nummer ein',
	tuijianyaoqingma: 'Empfohlener Einladungscode',
	wanchengzhuce: "komplette Registrierung",
	yiyouzhanghao: "bestehendes Konto",
	qingdenglu: "Bitte einloggen",
	remenhuati: "angesagtes Thema",
	news1: "Einführung in die Plattform",
	news2: "Spielanleitung",
	news3: "Aktivität",
	renliulan: "Leute stöbern",
	hezhi: "und Wert",
	lunshu: "Anzahl der Runden",
	做多: "TU mehr",
	做空: "kurz",
	多单: "Lange Bestellung",
	多双: "mehrere Paare",
	空单: "leere Bestellung",
	空双: "leeres Doppel",
	平单: "flache Bestellung",
	平双: "flaches Doppelzimmer",
	极阴: "Extrem bewölkt",
	极阳: "extreme Sonne",
	qi: "Erwarten",
	juli: "Distanz",
	lunjiezhi: "Aufrunden",
	yifengpan: "Geschlossen",
	yiguanbi: "geschlossen",
	yonghu: "Benutzer",
	danlunjiaoyi: "Einzelrundentransaktion",
	caozuo: "arbeiten",
	pljoin: "Treten Sie dem Chatroom erfolgreich bei",
	pltingshou: "Produkt wurde eingestellt",
	gengou: "Folgekauf",
	quedinggengou: "Möchten Sie den Kauf wirklich weiterverfolgen?",
	wanjia: "Spieler",
	leibie: "Kategorie",
	jine: "die Summe",
	gouru: "kaufen",
	zanweigouru: "Noch nicht gekauft",
	qigou: "kaufen",
	gourushuliang: "Kaufmenge",
	changgui: "konventionell",
	shuzi: "Nummer",
	yinglihezhi: "Gewinn und Wert",
	shouyijieshao: "Leistungseinführung",
	lijicunru: "Jetzt einzahlen",
	kaihaojieguo: "Offenes Zahlenergebnis",
	kaihaoqushi: "offener Zahlentrend",
	chanpingshuoming: "Produktbeschreibung",
	qingchuhuancun: "Cache leeren",
	youke: "Touristen",
	gongxi: "Glückwunsch",
	zai: "existieren",
	yingli: "profitieren",
	xiadanchenggong: "erfolgreich bestellt",
	jiazaizhong: "Wird geladen...",
	guanbitg: "Geschlossen, hör auf zu kaufen",
	xiangoudl: "Sie können nur die aktuelle Runde kaufen",
	liaotiantip: "Das System hat die Rede gesperrt, bitte wählen Sie „Kauf“.",
	tishi: "Hinweis",
	zhidao: "wusste",
	zhuanchusb: "Die Übertragung ist fehlgeschlagen",
	zhuanchusbs: "Unzureichendes Guthaben, Übertragung fehlgeschlagen",
	buyxq: "Einzelheiten zum Kauf",
	orderNo: "Rechnungsnummer",
	plan_name: "Rauminformationen",
	buyxx: "Informationen zum Kauf",
	haoxx: "Details zur Nummernauswahl",
	buysj: "Kaufzeit",
	yilou: "auslassen",
	fzjqb: "In die Zwischenablage kopiert",
	chanpin1: "Öffnungszeit: Alle 3,5 Minuten gibt es eine Runde, die am selben Tag um 10:00 Uhr öffnet und am nächsten Tag um 02:00 Uhr schließt.",
	chanpin2: "In jeder Runde gibt es 3 Zahlen von 0 bis 9, und die Summe der 3 Zahlen stellt das Endergebnis dar. Sie können Long, Short, Flat, Single, Flat, Bipolar, Positiv, Negativ und Zahlen als Ergebnis wählen Investition.",
	Tips: {
		wjmm: "Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an den Kundendienst, um es zu ändern!",
		dlcg: "Anmeldung erfolgreich",
		tjcg: "erfolgreich eingereicht",
		zanwu: "Keine Daten",
		buyizhi: "Die zwei Passwörter stimmen nicht überein",
		zcbz: "Ihr Vermögen reicht nicht aus, bitte zahlen Sie ein",
		pltxje: "Bitte geben Sie den Auszahlungsbetrag ein",
		zdtx: "Mindestauszahlung 100",
		youke: "Sehr geehrter Besucher, bitte registrieren Sie sich als formelles Mitglied.",
		szcg: "erfolgreich eingestellt"
	},
	Recharge: {
		title: "Ein- und Auszahlung",
		usdtrecharge: "USDT-Aufladung",
		usdtwithdrawal: "USDT-Abhebung",
		bankrecharge: "Bankaufladung",
		bankwithdrawal: "Bankabhebung"
	},
	Rechargecoin: {
		title: "USDT-Aufladung",
		downbtn: "Klicken Sie hier zum Herunterladen",
		downurl: "Einzahlungsadresse",
		copybtn: "Klicken Sie zum Kopieren",
		amount: "Quote",
		amountplaceholder: "Bitte geben Sie den Betrag ein",
		selectcontract: "einen Vertrag wählen",
		walletaddress: "Wallet-Adresse",
		walletaddressplaceholder: "Bitte geben Sie Ihre Wallet-Adresse ein",
		confirmbtn: "Klicken Sie zum Bestätigen",
		sharecode: "Teilen Sie QR-Code.png",
		writeamount: "Bitte geben Sie den Betrag ein",
		writeaddress: "Bitte geben Sie die Adresse ein"
	},
	Withdrawcoin: {
		title: "USDT-Bargeldabhebung",
		currency: "Währung",
		selectcontract: "einen Vertrag wählen",
		amount: "Quote",
		yue: "Gleichgewicht",
		yueplaceholder: "Bitte geben Sie den Betrag ein",
		walletaddress: "Wallet-Adresse",
		walletaddressplaceholder: "Bitte geben Sie Ihre Wallet-Adresse ein",
		confirmbtn: "Klicken Sie zum Bestätigen",
		sharecode: "Teilen Sie QR-Code.png",
		writeamount: "Bitte geben Sie den Betrag ein",
		writeaddress: "Bitte geben Sie die Adresse ein",
		writesuper: "Die Auszahlung übersteigt den Saldo"
	},
	Bankrecharge: {
		title: "Bankaufladung",
		amount: "die Summe",
		yue: "Gleichgewicht",
		selectcurrency: "Währung wählen",
		transamount: "umgerechneter Betrag",
		confirmbtn: "Klicken Sie zum Bestätigen",
		yueplaceholder: "Bitte geben Sie den Betrag ein",
		writeamount: "Bitte geben Sie den Betrag ein",
		writecurrency: "Bitte wählen Sie die Währung aus"
	},
	Bankwithdraw: {
		title: "Bargeldabhebung per Bankkarte",
		amount: "die Summe",
		yue: "Gleichgewicht",
		selectcurrency: "Währung wählen",
		transamount: "umgerechneter Betrag",
		confirmbtn: "Klicken Sie zum Bestätigen",
		yueplaceholder: "Bitte geben Sie den Betrag ein",
		writeamount: "Bitte geben Sie den Betrag ein",
		writecurrency: "Bitte wählen Sie die Währung aus",
		writesuper: "Die Auszahlung übersteigt den Saldo",
		bankinfo: "Bank Informationen",
		acc: "Konto",
		accname: "Kontoname",
		accbank: "Kontobank"
	},
    Loandetail:{
        title1: 'Möchten Sie einen Sofortkredit von uns erhalten?',
        title2: 'Befolgen Sie die folgenden einfachen Schritte!',
        troop1: '1. E-Mail-Bewerbung',
        troop2: '2. Besuchen Sie unser Büro',
        content1: 'Es ist Zeit, unser Büro zu besuchen und einen individuellen Kreditvertrag für Sie zu erstellen',
        troop3: '3. Unterzeichnen Sie den Kreditvertrag',
        content2: 'Informieren Sie sich über die Konditionen und unterzeichnen Sie dann den Kreditvertrag',
        troop4: '4. Bargeld erhalten',
        content3: 'Erhalten Sie Ihren Barkredit innerhalb einer Stunde!',
        content4: 'Darlehensart Privatdarlehen, Bereitstellung notwendiger persönlicher und finanzieller Informationen wie Einkommen, Bonitätshistorie usw. Überprüfung und Bewertung Bewerten Sie Ihr Kreditrisiko und entscheiden Sie, ob Sie einen Kredit bewilligen. Genehmigung und Bedingungen Sobald Ihr Kreditantrag genehmigt wurde, erhalten Sie eine formelle Kreditgenehmigungsbenachrichtigung mit Bedingungen wie Kredithöhe, Zinssatz, Rückzahlungszeitraum usw. Unterzeichnung eines Vertrags.Bei einem Darlehen handelt es sich um ein einmaliges Darlehen. Bei der Rückzahlung* handelt es sich um eine einmalige Rückzahlung gemäß Vertrag. Der jährliche Zinssatz beginnt bei 1 %. Um kulturelle Unterschiede zu vermeiden, erfolgen alle Antragsformulare per E-Mail!',
        confirmtext: 'Beantragen Sie jetzt einen Kredit',
    },
	loan: {
		gm9: 'Finanzkredite',
		jd:'Darlehen',
		jdjl:'Kredit- und Kredithistorie',
		jdzq:'Kreditzyklus',
		jdsl:'Kreditmenge',
		tj:'einreichen',
		zq:'Zyklus',
		time:'Zeit',
		wdzc:'Alles muss zurückgegeben werden',
		jded:'Kreditbetrag',
		sshk:'Rückzahlung',
		hkjl:'Rückzahlungshistorie',
		hksl:'Rückzahlungsbetrag',
		nodata:'Keine Daten',
		ksshijian: 'Anfangszeit',
        jieshujian: 'Endzeit',
		t:'Himmel',
		zt:'Zustand',
		shz:'in Prüfung',
		wtg:'Nicht bestanden',
		tg:'passieren'
	}
}
export default ge; 