<template>
    <div class="v_help g-flex-column">
        <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{$t('loan.gm9')}}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <!-- <head-back bgColor="none" fontColor="#fff"> {{$t('loan').gm9}} </head-back> -->
        <div class="loan_page">
            <div class="tab_box">
        <div class="tab_list">
            <div class="tab_item" :class="tab1Index == 0 ?'tab_item_bg':''" @click="onTab1(0)">{{$t(`loan.jd`)}}</div>
            <div class="tab_item" :class="tab1Index == 1 ?'tab_item_bg':''" @click="onTab1(1)">{{$t(`loan.jdjl`)}}</div>
        </div>
        <div class="tab_center" v-if="tab1Index == 0">
            <div class="tab_input_box tab_input_border">
                <div class="tab_input_text">{{$t(`loan.jdzq`)}}</div>
                <input v-model="daypopitem" class="tab_input" type="text" readonly="false" @click="isOnpop">
                <div class="tab_input_text">{{$t(`loan.t`)}}</div>
                <div class="pop_day" v-if="ispop">
                    <div class="pop_day_item" v-for="(item,index) in helpList" :key="index" @click="ondayPop(item)">
                        {{item.day}}{{$t(`loan.t`)}}
                    </div>
                </div>
            </div>
            <div class="tab_input_box">
                <div class="tab_input_text">{{$t(`loan.jdsl`)}}</div>
                <input class="tab_input" @input="input1Number=input1Number.replace(/[^0-9.]|^0/g,'')" v-model="input1Number" type="text">
                <div class="tab_input_text">USD</div>
            </div>
            <div class="tab_button" @click="apiloanOrderHandel">
                {{$t(`loan.tj`)}}
            </div>
        </div>
        <div class="tab_center" v-else>
            <div v-if="record1List.length > 0">
                <div class="tab_center_top">
                    <!-- <div class="tab_center_top_item">{{$t(`loan.zq`)}}</div> -->
                    <div class="tab_center_top_item">{{$t(`loan.jdsl`)}}</div>
                    <div class="tab_center_top_item">{{$t(`loan.ksshijian`)}}</div>
                    <div class="tab_center_top_item">{{$t(`loan.jieshujian`)}}</div>
                    <div class="tab_center_top_item">{{$t(`loan.zt`)}}</div>
                </div>
                <div class="tab_center_cent">
                    <div class="tab_center_cent_box" v-for="(item,index) in record1List" :key="index">
                        <!-- <div class="tab_center_cent_item">{{item.long}}{{$t(`loan.t`)}}</div> -->
                        <div class="tab_center_cent_item">{{item.amount}}</div>
                        <div class="tab_center_cent_item">{{timeStr(item.createtime)}}</div>
                        <div class="tab_center_cent_item">{{timeStr(item.endtime)}}</div>
                        <div class="tab_center_cent_item">
                            <span v-if="item.status == 0">{{$t(`loan.wtg`)}}</span>
                            <span v-else-if="item.status == 1">{{$t(`loan.tg`)}}</span>
                            <span v-else-if="item.status == 2">{{$t(`loan.shz`)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no_data">{{$t(`loan.nodata`)}}</div>
        </div>
      </div>
      <div class="tab_box">
        <div class="tab_title">{{$t(`loan.wdzc`)}}</div>
        <div class="tab_center">
            <div class="tab_input_box tab_input_box_bg">
                <div class="tab_input_text">{{$t(`loan.jded`)}}</div>
                <input class="tab_input" type="text" v-model="total" readonly="false">
                <div class="tab_input_text">USD</div>
            </div>
        </div>
      </div>
      <div class="tab_box">
        <div class="tab_list">
            <div class="tab_item" :class="tab2Index == 0 ?'tab_item_bg':''" @click="onTab2(0)">{{$t(`loan.sshk`)}}</div>
            <div class="tab_item" :class="tab2Index == 1 ?'tab_item_bg':''" @click="onTab2(1)">{{$t(`loan.hkjl`)}}</div>
        </div>
        <div class="tab_center" v-if="tab2Index == 0">

            <div class="tab_input_box">
                <div class="tab_input_text">{{$t(`loan.hksl`)}}</div>
                <input class="tab_input" @input="treb=treb.replace(/[^0-9.]|^0/g,'')" type="text" v-model="treb">
                <div class="tab_input_text">USD</div>
            </div>
            <div class="tab_button" @click="apirebackOrderHandel">
                {{$t(`loan.tj`)}}
            </div>
        </div>
        <div class="tab_center" v-else>
            <div v-if="record2List.length > 0">
                <div class="tab_center_top">
                    <div class="tab_center_top_item">{{$t(`loan.hksl`)}}</div>
                    <div class="tab_center_top_item">{{$t(`loan.time`)}}</div>
                    <div class="tab_center_top_item">{{$t(`loan.zt`)}}</div>
                </div>
                <div class="tab_center_cent">
                    <div class="tab_center_cent_box" v-for="(item,index) in record2List" :key="index">
                        <div class="tab_center_cent_item">{{item.amount}}</div>
                        <div class="tab_center_cent_item">{{timeStr(item.createtime)}}</div>
                        <div class="tab_center_cent_item">
                            <span v-if="item.status == 0">{{$t(`loan.wtg`)}}</span>
                            <span v-else-if="item.status == 1">{{$t(`loan.tg`)}}</span>
                            <span v-else-if="item.status == 2">{{$t(`loan.shz`)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no_data">{{$t(`loan.nodata`)}}</div>
        </div>
      </div>
        </div>
      
    </div>
  </template>
  
  <script>
  import loanApi from "@/api/loan";
//   import HeadBack from '@/components/HeadBack.vue'
//   import { apiLoanList,apiloanOrder,apiloanOrderList,apigetTotalLoan,apirebackOrder,apirebackOrderList } from "@/utils/api.js";
//   import { formatDate } from '@/utils/index'
    import time from "@/utils/time";
  export default {
    name:'Loan',
    // components: { HeadBack },
    data() {
      return {
        tab1Index:0,
        tab2Index:0,
        record1List:[],
        record2List:[],
        show: false,
        item: {},
        userInfo: {
          userReal: {},
        },
        helpList: [],
        daypopitem:[],
        OrderList:[],
        ispop:false,
        loanId:'',
        input1Number:'',
        total:0,
        reback:0,
        treb:''
      };
    },
    created() {
        this.loan()
        this.loanTotal()
        this.loanHistory()
        this.rebackHistory()
    },
    methods: {
        timeStr(items) {
            return time.formatDatethree(items * 1000);
        },
        onTab1(e){
            this.tab1Index = e
        },
        onTab2(e){
            this.tab2Index = e
        },
        ondayPop(e){
            this.daypopitem = e.day
            this.loanId = e.id
            this.ispop = !this.ispop
        },
        isOnpop(){
            this.ispop = !this.ispop
        },
        changego() {
            this.$router.go(-1);
        },
        loan(){
            loanApi.loan().then((data)=>{
                if(data.data.list.length){
                    this.helpList = data.data.list
                }

            })
        },
        loanTotal(){
            loanApi.getTotalLoan().then((data)=>{
                if(data.code == 1){
                    this.total = data.data.total
                    this.reback = data.data.reback      
                }
            })
        },
        loanHistory(){
            loanApi.getLoanHistory().then((data)=>{
                if(data.data.list.length){
                    this.record1List = data.data.list
                }
            })
        },
        rebackHistory(){
            loanApi.getRebackHistory().then((data)=>{
                if(data.data.list.length){
                    this.record2List = data.data.list
                }
            })
        },
        apiloanOrderHandel(){
            if(!this.loanId ||!this.input1Number){
                this.$toast(this.$t('loan.dataerror'));
            }
            let data = {
                loan_id:this.loanId,
                loan_amount:this.input1Number
            }
            loanApi.addLoan(data).then((data)=>{
              this.$toast.success(data.msg);
            }).catch( error =>  {
              this.$toast.fail(error.msg);
            } );
        },
        apirebackOrderHandel(){
            if(!this.treb){
                this.$toast(this.$t('loan.dataerror'));
            }
            let data = {
                reback_amount:this.treb
            }
            loanApi.addReback(data).then((data)=>{
                    this.$toast.success(data.msg);
                
                
            }).catch( error =>  {
              this.$toast.fail(error.msg);
            } );
        }
    },
    computed: {
      
    },
  };
  </script>
  
  <style lang="less" scoped>
    .no_data {
  text-align: center;
}

.loan_page {
  overflow-y: auto;
  flex: 1;
}

.v_help {
  height: 100%;
}
.v_help .tab_box {
  margin: 10.66667vw 5.33333vw;
  border-radius: 2.13333vw;
  background-color: #242933;
  overflow: hidden;
}
.v_help .tab_box .tab_title {
  padding: 5.33333vw;
  padding-bottom: 0;
  font-size: 3.5vw;
}
.v_help .tab_box .tab_list {
  display: flex;
  height: 42px;
}
.v_help .tab_box .tab_list .tab_item {
  flex: 1;
  text-align: center;
  line-height: 42px;
  background-color: #2d343f;
  font-size: 3.5vw;
  color: #fff;
}
.v_help .tab_box .tab_list .tab_item_bg {
  background-color: #7d58c6;
  color: #9fa6b5;
}
.v_help .tab_box .tab_center {
  padding: 5.33333vw;
}
.v_help .tab_box .tab_center .tab_center_top {
  display: flex;
  line-height: 10vw;
}
.v_help .tab_box .tab_center .tab_center_top .tab_center_top_item {
  flex: 1;
  text-align: center;
}
.v_help .tab_box .tab_center .tab_center_cent {
  max-height: 30vw;
  overflow-y: auto;
}
.v_help .tab_box .tab_center .tab_center_cent .tab_center_cent_box {
  display: flex;
  line-height: 10vw;
}
.v_help .tab_box .tab_center .tab_center_cent .tab_center_cent_box .tab_center_cent_item {
  flex: 1;
  text-align: center;
  line-height: 20px;
}
.v_help .tab_box .tab_center .tab_input_box {
  display: flex;
  align-items: center;
  height: 17vw;
  padding-left: 5.33333vw;
  padding-right: 5.33333vw;
  position: relative;
}
.v_help .tab_box .tab_center .tab_input_box .pop_day {
  position: absolute;
  background-color: #000;
  right: 10vw;
  bottom: -36vw;
  z-index: 9;
  border: 1px solid #e9ebf2;
}
.v_help .tab_box .tab_center .tab_input_box .pop_day .pop_day_item {
  padding: 3.5vw;
  width: 30vw;
}
.v_help .tab_box .tab_center .tab_input_box .tab_input {
  flex: 1;
  outline: none;
  border: none;
  background: none;
  width: 10px;
  text-align: center;
}
.v_help .tab_box .tab_center .tab_input_box .tab_input_text {
  font-size: 3.5vw;
  display: contents;
  margin-right: 3.5vw;
}
.v_help .tab_box .tab_center .tab_input_box_bg {
  background-color: #2d343f;
  border: 1px solid #2d343f;
  border-radius: 2.13333vw;
}
.v_help .tab_box .tab_center .tab_input_border {
  border-bottom: 1px solid #d7d7d7;
}
.v_help .tab_box .tab_center .tab_button {
  background-color: #7d58c6;
  color: #fff;
  height: 10vw;
  border-radius: 2.13333vw;
  line-height: 10vw;
  text-align: center;
  font-size: 3.4vw;
  margin-top: 3.5vw;
}

/*# sourceMappingURL=loan.css.map */

  </style>
  