const ja={
	jiantizhongwen: "簡体字中国語",
	xianlu: "ライン",
	dianwei: "現在のポイント",
	wodezichan: "私の資産",
	touzikuaixun: "投資ニュース",
	jiaoyiyonghu: "トランザクションユーザー",
	jiaoyipinglei: "取引カテゴリー",
	danlunhuoli: "単一ラウンドの利益",
	jiaoyiriqi: "取引日",
	dengluhouchakan: "ログインして表示",
	shouye: "表紙",
	jiaoyidating: "トレーディングフロア",
	yucun: "プリストア",
	zoushi: "傾向",
	wode: "私の",
	baocun: '保存',
	genggai: 'アバターを変更する',
	zuiditouzi: "最低投資額",
	zuidijinru: "最低エントリー",
	jichulicai: "一般会員",
	zishenlicai: "プラチナ会員",
	dashilicai: "ダイヤモンド会員",
	zhuanjialicai: "VIP会員",
	基础理财: "基本的な財務管理",
	资深理财: "上級財務管理者",
	大师理财: "マスターファイナンス",
	专家理财: "エキスパートファイナンス",
	huiyuanquanyi: "会員特典",
	zuoriyingli: "昨日の利益",
	daikuanzonge: 'ローン総額',
	zongzichan: "総資産",
	chucunjin: "お金を節約",
	jinriyingli: "今日の利益",
	zhuanchu: "転出",
	cunru: "デポジット",
	zhanghuxinxni: "口座情報",
	lishizhangdan: "歴史的な請求書",
	zijinminxi: "資金調達の詳細",
	renwuzhongxin: "タスクセンター",
	xiaoxigonggao: "ニュース発表",
	tuijianfenxiang: "共有することをお勧めします",
	daikuanxiangqing:'ローンの詳細',
	huodongzhongxin: "アクティビティセンター",
	jibenziliao: "基本情報",
	dengluzhanghao: "ログインアカウント",
	nicheng: "ニックネーム",
	idcard: '識別番号',
	congxiangce: 'アルバムから選ぶ',
    xitongtu: 'システムのデフォルト画像',
	shezhi: "設定",
	genggaixianlu: "ルート変更",
	guanyuwomen: "私たちについて",
	xitongshezhi: "システム設定",
	yuyanshezhi: "言語設定",
	tuichudenglu: "サインアウト",
	bangdingyinhangka: "銀行カードをバインドする",
	bangdingxuniqianbao: "仮想ウォレットをバインドする",
	anquanma: "セキュリティコード",
	xiugailenglumima: "ログインパスワードを変更する",
	yinhangkaguanli: "銀行カードの管理",
	tianjiayinhangka: "銀行カードを追加する",
	xuniqianbaoguanli: "仮想ウォレット管理",
	tianjiaxuniqianbao: "仮想ウォレットを追加する",
	chiakren: "カード所有者",
	yinhangkakahao: "銀行カード番号",
	yinhangmingcheng: "銀行名",
	yhkTps: "カード所有者自身の銀行カードをバインドしてください",
	nixiqianbaodizhi: "仮想ウォレットアドレス",
	xuniqianbaoleixing: "仮想ウォレットの種類",
	xnqbTps: "リマインダー: USDT アドレス タイプを入力してください (例: trc20、erc20、omni)",
	plchikaren: "カード所有者を入力してください",
	plkahao: "カード所有者のカード番号を入力してください",
	plyinhangmingcheng: "銀行名を入力してください",
	plqbdz: "仮想ウォレットのアドレスを入力してください",
	plqblx: "仮想ウォレットのタイプを入力してください",
	shezhianquanma: "セキュリティコードを設定する",
	yuananquanma: "オリジナルのセキュリティコード",
	xinanquanma: "新しいセキュリティコード",
	planquanma: "元のセキュリティコードを入力してください",
	plxinanquanma: "新しいセキュリティコードを入力してください",
	queren: "確認する",
	quxiao: "キャンセル",
	anquanmaTps: "セキュリティコードを設定してください。銀行カードのパスワードと同じにしないでください。",
	anquanmaTpsTow: "お客様各位、このセキュリティコードは転送時のパスワードです。セキュリティ上の理由から、ログインパス ワードと同じにならないようにしてください。",
	xiugaidenglu: "ログインパスワードを変更する",
	jiumima: "以前のパスワード",
	xinmima: "新しいパスワード",
	querenmima: "パスワードを認証する",
	pljiumima: "古いパスワードを入力してください",
	plxinmima: "新しいパスワードを入力してください",
	plquerenmima: "パスワードをもう一度入力してください",
	wancheng: "仕上げる",
	kaishitouzi: "投資を始める",
	kaiTps: "現在の回線が利用できない場合は、別の回線に切り替えてみてください",
	zuiyou: "最高",
	dangaqianxianlu: "現在の行",
	dangqianbanben: "現行版",
	banquansuoyou: "無断転載を禁じます",
	yinglizhendong: "収益ショック",
	gensuixitong: "システムに従ってください",
	zhuanchujine: "払込金額",
	plzhuanchu: "送金額を入力してください",
	zhuanrujine: "払込金額",
	plzhuanru: "送金額を入力してください",
	zhuanchuTps: "注意: ご質問がある場合は、プラットフォームのカスタマー サービスにお問い合わせください。",
	xuanzeyh: "引き出し用の銀行カードを選択してください",
	xuanzehb: "出金用の仮想ウォレットを選択してください",
	tijiaoshenqing: "申請書を提出する",
	shuruanquanma: "セキュリティコードを入力してください",
	xianshangcunru: "オンラインで入金する",
	saomacunru: "USDT",
	wangyincunru: "銀行預金",
	shijian: "時間",
	upshijian: "開始時間",
	downshijian: "終了時間",
	wuTps: "関連するデータはありません~",
	jiazaicx: "リロード",
	di: "いいえ。",
	lun: "車輪",
	weiyingli: "不採算",
	yishouli: "承認されました",
	zhuangtai: "州",
	chongzhi: "リセット",
	riqi: "日付",
	lianxikefu: 'カスタマーサービスに連絡する',
	CapActive: {
		chongzhi: "デポジット",
		tixian: "お金を引き出す",
		goumai: "買う",
		yingli: "利益",
		daikuan:'ローン',
        huankuan:'返済',
		kuisun: '損失',
		zengsong: "贈り物",
		weitongguo: "不合格",
		yitongguo: "合格した",
		yijujue: "拒否されました",
		all: "全て"
	},
	zhanneixiaoxi: "駅ニュース",
	pingtaigonggao: "プラットフォームの発表",
	fuzhilanjie: "リンクをコピーする",
	denglu: "ログイン",
	zhuce: "登録する",
	jizhumima: "パスワードを覚える",
	wangjimima: "パスワードを忘れる",
	youkedenglu: "訪問者ログイン",
	zhucezhanghao: "アカウント登録",
	plusername: "ユーザー名を入力してください",
	plpassword: "パスワードを入力してください",
	replpassword:'パスワードをもう一度入力してください',
	plinvitecode: "招待コードを入力してください",
	plidcard: 'ID番号を入力してください',
	tuijianyaoqingma: 'おすすめの招待コード',
	wanchengzhuce: "登録を完了する",
	yiyouzhanghao: "既存のアカウント",
	qingdenglu: "サインインしてください",
	remenhuati: "ホットな話題",
	news1: "プラットフォームの紹介",
	news2: "遊び方",
	news3: "活動",
	renliulan: "人々が閲覧する",
	hezhi: "そして価値観",
	lunshu: "ラウンド数",
	做多: "もっとやれ",
	做空: "短い",
	多单: "ロングオーダー",
	多双: "複数のペア",
	空单: "空の注文",
	空双: "空のダブル",
	平单: "フラットオーダー",
	平双: "フラットダブル",
	极阴: "非常に曇り",
	极阳: "極端な太陽",
	qi: "期待する",
	juli: "距離",
	lunjiezhi: "切り上げする",
	yifengpan: "閉まっている",
	yiguanbi: "閉まっている",
	yonghu: "ユーザー",
	danlunjiaoyi: "シングルラウンドトランザクション",
	caozuo: "操作する",
	pljoin: "チャット ルームに正常に参加しました",
	pltingshou: "製品は製造中止となりました",
	gengou: "フォローアップ購入",
	quedinggengou: "購入をフォローアップしてもよろしいですか?",
	wanjia: "プレーヤー",
	leibie: "カテゴリー",
	jine: "総額",
	gouru: "買う",
	zanweigouru: "まだ購入していません",
	qigou: "購入",
	gourushuliang: "購入数量",
	changgui: "従来の",
	shuzi: "番号",
	yinglihezhi: "利益と価値",
	shouyijieshao: "特典紹介",
	lijicunru: "今すぐ入金",
	kaihaojieguo: "オープンナンバーの結果",
	kaihaoqushi: "オープンナンバーの傾向",
	chanpingshuoming: "製品説明",
	qingchuhuancun: "キャッシュの消去",
	youke: "観光客",
	gongxi: "おめでとう",
	zai: "存在する",
	yingli: "利益",
	xiadanchenggong: "正常に注文されました",
	jiazaizhong: "読み込み中...",
	guanbitg: "閉店しました、購入を中止します",
	xiangoudl: "現在のラウンドのみ購入できます",
	liaotiantip: "システムによりスピーチが禁止されています。購入することを選択してください",
	tishi: "ヒント",
	zhidao: "知っていた",
	zhuanchusb: "転出に失敗しました",
	zhuanchusbs: "残高不足のため送金できませんでした",
	buyxq: "購入詳細",
	orderNo: "請求書番号",
	plan_name: "お部屋のご案内",
	buyxx: "購入情報",
	haoxx: "番号選択の詳細",
	buysj: "購入時間",
	yilou: "省略",
	fzjqb: "クリップボードにコピーされました",
	chanpin1: "オープン時間: 3.5 分ごとに 1 ラウンド、同日の午前 10 時にオープンし、翌日の午前 2 時にクローズします。",
	chanpin2: "各ラウンドには 0 ～ 9 の 3 つの数字があり、3 つの数字の合計が最終結果となります。ロング、ショート、フラット、シングル、フラット、バイポーラ、ポジティブ、ネガティブ、および数字の結果として進むことができます。投資。",
	Tips: {
		wjmm: "パスワードを忘れた場合は、カスタマー サービスに連絡して変更してください。",
		dlcg: "ログイン成功",
		tjcg: "正常に送信されました",
		zanwu: "データなし",
		buyizhi: "2 つのパスワードが一致しません",
		zcbz: "資産が不足していますので入金してください",
		pltxje: "出金額を入力してください",
		zdtx: "最低出金額 100",
		youke: "ご覧の皆様は、正式会員登録を行った上で操作をお願いいたします。",
		szcg: "正常に設定されました"
	},
	Recharge: {
		title: "入金と出金",
		usdtrecharge: "USDTリチャージ",
		usdtwithdrawal: "USDTの出金",
		bankrecharge: "銀行リチャージ",
		bankwithdrawal: "銀行引き出し"
	},
	Rechargecoin: {
		title: "USDTリチャージ",
		downbtn: "クリックしてダウンロード",
		downurl: "入金アドレス",
		copybtn: "クリックしてコピーします",
		amount: "クォータ",
		amountplaceholder: "金額を入力してください",
		selectcontract: "契約を選ぶ",
		walletaddress: "ウォレットアドレス",
		walletaddressplaceholder: "ウォレットアドレスを入力してください",
		confirmbtn: "クリックして確認します",
		sharecode: "QR コードを共有.png",
		writeamount: "金額をご記入ください",
		writeaddress: "住所をご記入ください"
	},
	Withdrawcoin: {
		title: "USDTによる現金引き出し",
		currency: "通貨",
		selectcontract: "契約を選ぶ",
		amount: "クォータ",
		yue: "バランス",
		yueplaceholder: "金額を入力してください",
		walletaddress: "ウォレットアドレス",
		walletaddressplaceholder: "ウォレットアドレスを入力してください",
		confirmbtn: "クリックして確認します",
		sharecode: "QR コードを共有.png",
		writeamount: "金額をご記入ください",
		writeaddress: "住所をご記入ください",
		writesuper: "出金額が残高を超えている"
	},
	Bankrecharge: {
		title: "銀行リチャージ",
		amount: "総額",
		yue: "バランス",
		selectcurrency: "通貨を選択",
		transamount: "換算金額",
		confirmbtn: "クリックして確認します",
		yueplaceholder: "金額を入力してください",
		writeamount: "金額をご記入ください",
		writecurrency: "通貨を選択してください"
	},
	Bankwithdraw: {
		title: "銀行カードによる現金引き出し",
		amount: "総額",
		yue: "バランス",
		selectcurrency: "通貨を選択",
		transamount: "換算金額",
		confirmbtn: "クリックして確認します",
		yueplaceholder: "金額を入力してください",
		writeamount: "金額をご記入ください",
		writecurrency: "通貨を選択してください",
		writesuper: "出金額が残高を超えている",
		bankinfo: "銀行情報",
		acc: "アカウント",
		accname: "アカウント名",
		accbank: "口座銀行"
	},
    Loandetail:{
        title1: '今すぐローンを申し込む',
        title2: '以下の簡単な手順に従ってください。',
        troop1: '1.メールでのお申込み',
        troop2: '2. 弊社オフィスへお越しください',
        content1: '私たちのオフィスを訪れて、自分用のカスタムローン契約を作成する時間です',
        troop3: '3. ローン契約書に署名する',
        content2: '利用規約を理解してからローン契約書に署名する',
        troop4: '4. 現金を受け取る',
        content3: '1時間以内に現金融資を受け取れます!',
        content4: 'ローンの種類 個人ローンでは、収入、信用履歴など、必要な個人情報および財務情報を提供します。レビューと評価 信用リスクを評価し、ローンを承認するかどうかを決定します。承認と条件 ローン申し込みが承認されると、融資額、金利、返済期間などの条件が記載された正式なローン承認通知が届きます。契約書に署名する.ご融資は1回限り、ご返済※は契約に基づく1回限りのご返済となります。年利1%～文化の違いを避けるため、お申込みはすべてメールで！',
        confirmtext: '今すぐローンを申し込む',
    },
	loan: {
		gm9: '金融融資',
		jd:'ローン',
		jdjl:'ローンと信用履歴',
		jdzq:'融資サイクル',
		jdsl:'融資額',
		tj:'提出する',
		zq:'サイクル',
		time:'時間',
		wdzc:'全て返却予定',
		jded:'融資額',
		sshk:'返済',
		hkjl:'返済履歴',
		hksl:'返済額',
		nodata:'データなし',
		ksshijian: '開始時間',
        jieshujian: '終了時間',
		t:'空',
		zt:'州',
		shz:'検討中',
		wtg:'不合格',
		tg:'合格'
	}
}

  export default ja;